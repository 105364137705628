<template>
  <div class="merge-fields-group-wrapper" :class="{ 'mb-2': !marginBottomEmtpy }">
    <h6 v-if="!hideTitle" class="h6">Merge fields</h6>
    <div v-click-outside="onClickOutside" class="mergefield-dropdown">
      <div class="mergefield-dropdown-element" :class="{ 'open': open }">
        <b-button class="mt-1" :variant="buttonVariant" :size="dropdownSize" @click="toggleOpen">Personalization <i class="uil uil-angle-down"></i></b-button>
        <ul>
          <li class="dropdown-item"><a href="javascript:;">Business Info <i class="uil uil-angle-right"></i></a>
            <ul>
              <li @click="appendField('[business_name]')">
                <a href="javascript:;"><i class="uil uil-copy"></i> Business Name</a>
              </li>
              <li @click="appendField('[business_phone]')">
                <a href="javascript:;"><i class="uil uil-copy"></i> Business Phone</a>
              </li>
              <li @click="appendField('[business_website]')">
                <a href="javascript:;"><i class="uil uil-copy"></i> Business Website</a>
              </li>
              <li @click="appendField('[address]')">
                <a href="javascript:;"><i class="uil uil-copy"></i> Address</a>
              </li>
              <li @click="appendField('[city]')">
                <a href="javascript:;"><i class="uil uil-copy"></i> City</a>
              </li>
              <li @click="appendField('[state]')">
                <a href="javascript:;"><i class="uil uil-copy"></i> State</a>
              </li>
              <li @click="appendField('[zip]')">
                <a href="javascript:;"><i class="uil uil-copy"></i> Zip</a>
              </li>
              <li @click="appendField('[country]')">
                <a href="javascript:;"><i class="uil uil-copy"></i> Country</a>
              </li>
<!--              <li v-if="!hideGoogleLinks" @click="appendField('[review_link]')">
                <a href="javascript:;"><i class="uil uil-copy"></i> Review Link</a>
              </li>
              <li v-if="!hideGoogleLinks" @click="appendField('[google_business_profile_link]')">
                <a href="javascript:;"><i class="uil uil-copy"></i> Google Business Profile Link</a>
              </li>-->
            </ul>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    buttonVariant:{
      type:String,
      default:'outline-dark'
    },
    mceField: {
      type: Object,
      required: false,
      default: null,
    },
    textField: {
      type: Object,
      required: false,
      default: null,
    },
    showRedemption: {
      type: Boolean,
      default: false,
    },
    hideExpireDate: {
      type: Boolean,
      default: false,
    },
    showKeyword: {
      type: Boolean,
      default: false,
    },
    showContactLink: {
      type: Boolean,
      default: false,
    },
    hideTitle: {
      type: Boolean,
      default: false,
    },
    dropdownSize: {
      type: String,
      default: '',
    },
    hideContactDropdown: {
      type: Boolean,
      default: false,
    },
    hideSamcartDropdown: {
      type: Boolean,
      default: false,
    },
    marginBottomEmtpy: {
      type: Boolean,
      default: false,
    },
    hideGoogleLinks:{
      type:Boolean,
      default:false
    }
  },

  data: function () {
    return {
      open: false,
    }
  },

  computed: {
    user() {
      return this.$store.getters['auth/user']
    },

    useSamcartIntegration() {
      return !this.hideSamcartDropdown && this.user && this.user.business && this.user.business.use_integration && this.user.business.has_integrations.samcart
    },

    useStaticCoupon() {
      return this.$store.getters['keyword/useStaticCoupon']
    },

    useGotoWebinarIntegration() {
      return !this.hideContactDropdown && this.user && this.user.business && this.user.business.has_integrations.goto
    },

    customFields() {
      return this.$store.getters["auth/customFields"];
    },
  },

  methods: {
    onClickOutside() {
      this.open = false
    },
    toggleOpen() {
      this.open = true
    },
    appendField(field) {
      if (this.textField && this.textField.$el) {
        this.textField.$el.querySelector('textarea').focus()
        document.execCommand('insertText', false, field)
      } else {
        this.$emit('selected', field)
      }
      this.open = false
    }
  }
}
</script>
<style lang="scss" scoped>
.merge-fields-group-wrapper {
  .mergefield-dropdown,
  .mergefield-dropdown ul,
  .mergefield-dropdown li,
  .mergefield-dropdown a {
    margin: 0;
    padding: 0;
    line-height: normal;
    list-style: none;
    display: block;
    position: relative;
  }
  .mergefield-dropdown {
    display: inline-block;
  }

  .mergefield-dropdown ul {
    opacity: 0;
    position: absolute;
    top: 100%;
    left: -9999px;
    z-index: 999;
    -webkit-transition: opacity .3s;
    transition: opacity .3s;
  }

  .mergefield-dropdown-element.open>ul {
    left: 0;
    opacity: 1;
    z-index: 1000;
    box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, .15);
    background: #fff;
    animation-name: DropDownSlide;
    animation-duration: .3s;
    animation-fill-mode: both;
  }

  .mergefield-dropdown li:hover>ul {
    left: 0;
    opacity: 1;
    z-index: 1000;
    box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, .15);
    animation-name: DropDownSlide;
    animation-duration: .3s;
    animation-fill-mode: both;
  }

  .mergefield-dropdown ul li:hover>ul {
    top: 0;
    left: 100%;
  }

  .mergefield-dropdown li {
    cursor: default;
    float: left;
    white-space: nowrap;
  }

  .mergefield-dropdown ul li {
    float: none;
  }

  /* sub width */
  .mergefield-dropdown ul {
    min-width: 12em;
    -webkit-box-shadow: 2px 2px 2px rgba(0, 0, 0, 0.25);
    box-shadow: 2px 2px 2px rgba(0, 0, 0, 0.25);
  }

  /* root */
  .mergefield-dropdown a {
    padding: 0 10px;
    color: #333;
    font-weight: normal;
    font-size: 16px;
    line-height: 40px;
    text-decoration: none;
  }

  /* root: hover/persistence */
  .mergefield-dropdown a:hover,
  .mergefield-dropdown a:focus,
  .mergefield-dropdown li:hover a {
    background: #fff;
    color: #333;
  }

  /* 2 */
  .dropdown-item a,
  .mergefield-dropdown li li a,
  .mergefield-dropdown li:hover li a {
    padding: 10px 10px;
    background: #fff;
    color: #333;
    font-size: 14px;
    line-height: normal;
    display: flex;
    justify-content: flex-start;
  }

  .dropdown-item a {
    justify-content: space-between;
  }

  /* 2: hover/persistence */
  .mergefield-dropdown li:hover li a:hover,
  .mergefield-dropdown li:hover li a:focus,
  .mergefield-dropdown li:hover li:hover a {
    background: #efefef;
  }
}
</style>
